@charset "utf-8";


// ===================================================================
// フォントサイズ Yahoo YUI
// ===================================================================
$fontSizeList = 62%, 70%, 77%, 85%, 93%, 100%, 108%, 116%, 124%, 131%, 139%, 147%, 154%, 162%, 170%, 177%, 185%, 193%, 200%, 208%, 216%, 224%, 231%, 239%, 247%, 254%, 262%, 270%, 277%, 285%, 293%, 300%, 308%
fz($size)
	if $size < 8
		$size = 8
	if $size > 40
		$size = 40
	return $fontSizeList[$size - 8]
