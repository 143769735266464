/*
Theme Name: 太陽幼稚園 夏
Theme URI: taiyo-kinder.com
Description: 太陽幼稚園 夏のテンプレート
Author: asoblock
Version: 1.0.0
*/

 /*
-----------------------------------------------
共通のスタイル
-----------------------------------------------
*/

@import "_setting";
@import "_function";
@import "_mixin";

/* -------------------------------------------------
タグのリセット
------------------------------------------------- */
h1, h2, h3, h4, h5, h6, div, p, ul, ol, li, dl, dt, dd, form
	margin 0
	padding 0
if $use_html5
	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
		display block
		margin 0
		padding 0
img
	border 0
table
	border-collapse collapse
	border-spacing 0
p, dd
	text-align justify
	text-justify inter-ideograph
	line-break strict
pre, code, kbd, samp, tt
	font-family monospace
body
	line-height 0

/* -------------------------------------------------
タグの初期化
------------------------------------------------- */
// http://coliss.com/articles/build-websites/operation/css/font-size-with-rem-by-snook-ca.html
html
	font-size 62.5%
body
	margin 0
	padding 0
	fz-rem(12)
	font-family $base_font
	line-height 1.8
	text-align center
	color $font_color
	-webkit-text-size-adjust 100%
img
	vertical-align top
a:link
	color $link_color
	text-decoration none
a:active
	color $link_color_active
	text-decoration none
a:visited
	color $link_color_visited
	text-decoration none
a:hover
	color $link_color_hover
	text-decoration underline

/* -------------------------------------------------
共通クラス
------------------------------------------------- */
/* clearfix
------------------------------------------------- */
.cf
	zoom 1
	&:before, &:after
		content ""
		display table
	&:after
		clear both

/* ロールオーバー画像の透明度
------------------------------------------------- */
.alphaimage
	filter unquote('alpha(opacity=60)')
	-moz-opacity 0.60
	opacity 0.60

/* 注意事項
------------------------------------------------- */
.notice
	padding 0 0 0 1em
	text-indent -1em

/* wp comments
------------------------------------------------- */
.wp-comment
	display none

/* wp comments
------------------------------------------------- */
.g-warning
	padding 10px
	background #FFC4C4
	p
		fz-rem(16)
		font-weight bold
		color #333
		text-align center

/* -------------------------------------------------
header
------------------------------------------------- */
.g-header
	margin 0 0 80px
	min-height 144px
	background #F5F3E6 url(img/common/header_bg.png) left bottom repeat-x
	.g-wrapper
		position relative
		width $base_width
		margin 0 auto
		text-align left
	.g-logo
		position absolute
		top 25px
		left 0
	nav
		h2
			display none
	.g-nav
		ul
			list-style-type none
		li
			a
				text-decoration none
				transition-property color background
				transition-duration 0.3s
				//rollover-alpha()
				&:hover
					color #B8A761
	.g-nav-main
		position absolute
		top 106px
		left 0
		border-left 1px solid #666
		li
			float left
			padding 0 20px
			fz-rem(15)
			font-weight bold
			line-height 1.5
			border-right 1px solid #666
	.g-nav-sub
		//display none
		position absolute
		top -5000px
		li
			padding 0
			font-weight normal
			border none
	.g-nav-en
		position absolute
		top 113px
		left 580px
		li
			float left
			margin 0 25px 0 0
			padding 0 0 0 7px
			fz-rem(12)
			font-weight bold
			line-height 1.3
			background url(img/common/header_icon_arw.png) left center no-repeat
	.g-nav-info
		position absolute
		top 113px
		left 916px
		li
			float left
			margin 0 10px 0 0
			a
				display block
				overflow hidden
				width 14px
				height 14px
				text-indent 100%
				white-space no-wrap
				background-position left top
				background-repeat no-repeat
				transition-property background
				transition-duration 0.3s
		.g-nav-info-home
			a
				background-image url(img/common/header_icon_home.png)
				&:hover
					background-image url(img/common/header_icon_home_o.png)
		.g-nav-info-sitemap
			margin-right 7px
			a
				background-image url(img/common/header_icon_sitemap.png)
				&:hover
					background-image url(img/common/header_icon_sitemap_o.png)
		.g-nav-info-information
			margin-right 0
			a
				background-image url(img/common/header_icon_information.png)
				&:hover
					background-image url(img/common/header_icon_information_o.png)
	.g-address
		position absolute
		top 23px
		left 717px
		line-height 1.4
		dt
			fz-rem(14)
			font-weight bold
		dd
			fz-rem(12)
			span
				font-weight bold

/* header sub nav for pc
------------------------------------------------- */
.g-nav-sub-pc
	position absolute
	z-index 1
	width $base_width - 210px;
	padding 13px 0 13px 210px
	top 38px
	left 0
	border 3px solid #B7A665
	background url(img/common/header_nav_sub_bg.png) left top
	.g-arrow
		position absolute
		top -19px
		width 26px
		height 19px
		background url(img/common/header_nav_sub_arw.png) left top no-repeat
	p
		position absolute
		overflow hidden
		left 30px
		text-indent 100%
		white-space nowrap
		background-position left top
		background-repeat no-repeat
	ul
		overflow hidden
		padding 0 0 0 10px
		border-left 1px solid #C6A950
	li
		margin 7px 10px
		background #F7F0BB
		a
			display block
			overflow hidden
			width 230px
			height 68px
			text-indent 100%
			white-space nowrap
			background-position left top
			background-repeat no-repeat
			br
				display none
	/* kyoiku */
	&.g-nav-sub-kyoiku
		p
			width 125px
			height 24px
			top 80px
			background-image url(img/common/header_nav_sub_kyoiku.png)
		.g-arrow
			left 44px
	.g-nav-sub-taisetsu
		a
			background-image url(img/common/header_nav_sub_taisetsu.png)
			&:hover
				background-image url(img/common/header_nav_sub_taisetsu_o.png)
	.g-nav-sub-ajito
		a
			background-image url(img/common/header_nav_sub_ajito.png)
			&:hover
				background-image url(img/common/header_nav_sub_ajito_o.png)
	.g-nav-sub-gyoji
		a
			background-image url(img/common/header_nav_sub_gyoji.png)
			&:hover
				background-image url(img/common/header_nav_sub_gyoji_o.png)
	.g-nav-sub-fubo
		a
			background-image url(img/common/header_nav_sub_fubo.png)
			&:hover
				background-image url(img/common/header_nav_sub_fubo_o.png)
	.g-nav-sub-shizen
		a
			background-image url(img/common/header_nav_sub_shizen.png)
			&:hover
				background-image url(img/common/header_nav_sub_shizen_o.png)
	.g-nav-sub-rekishi
		a
			background-image url(img/common/header_nav_sub_rekishi.png)
			&:hover
				background-image url(img/common/header_nav_sub_rekishi_o.png)
	/* nyuen */
	&.g-nav-sub-nyuen
		p
			width 142px
			height 53px
			top 68px
			background-image url(img/common/header_nav_sub_nyuen.png)
		.g-arrow
			left 190px
	.g-nav-sub-bosyu
		a
			background-image url(img/common/header_nav_sub_bosyu.png)
			&:hover
				background-image url(img/common/header_nav_sub_bosyu_o.png)
	.g-nav-sub-nursery
		a
			background-image url(img/common/header_nav_sub_nursery.png)
			&:hover
				background-image url(img/common/header_nav_sub_nursery_o.png)
	.g-nav-sub-tonton
		a
			background-image url(img/common/header_nav_sub_tonton.png)
			&:hover
				background-image url(img/common/header_nav_sub_tonton_o.png)
	.g-nav-sub-ranran
		a
			background-image url(img/common/header_nav_sub_ranran.png)
			&:hover
				background-image url(img/common/header_nav_sub_ranran_o.png)
	.g-nav-sub-sansan
		a
			background-image url(img/common/header_nav_sub_sansan.png)
			&:hover
				background-image url(img/common/header_nav_sub_sansan_o.png)
	.g-nav-sub-aiai
		a
			background-image url(img/common/header_nav_sub_aiai.png)
			&:hover
				background-image url(img/common/header_nav_sub_aiai_o.png)
	.g-nav-sub-asoberuhi
		a
			background-image url(img/common/header_nav_sub_asoberuhi.png)
			&:hover
				background-image url(img/common/header_nav_sub_asoberuhi_o.png)
	.g-nav-sub-koe
		a
			background-image url(img/common/header_nav_sub_koe.png)
			&:hover
				background-image url(img/common/header_nav_sub_koe_o.png)
	.g-nav-sub-busmap
		a
			background-image url(img/common/header_nav_sub_busmap.png)
			&:hover
				background-image url(img/common/header_nav_sub_busmap_o.png)
	/* saiyo */
	&.g-nav-sub-saiyo
		p
			width 122px
			height 51px
			top 30px
			top 42px
			background-image url(img/common/header_nav_sub_saiyo.png)
		.g-arrow
			left 335px
	.g-nav-sub-saiyonagare
		a
			background-image url(img/common/header_nav_sub_saiyonagare.png)
			&:hover
				background-image url(img/common/header_nav_sub_saiyonagare_o.png)
	.g-nav-sub-kensyu
		a
			background-image url(img/common/header_nav_sub_kensyu.png)
			&:hover
				background-image url(img/common/header_nav_sub_kensyu_o.png)
	.g-nav-sub-interview
		a
			background-image url(img/common/header_nav_sub_interview.png)
			&:hover
				background-image url(img/common/header_nav_sub_interview_o.png)
	/* sotsugyosei */
	&.g-nav-sub-sotsugyosei
		p
			width 160px
			height 23px
			top 42px
			background-image url(img/common/header_nav_sub_sotsugyosei.png)
		.g-arrow
			left 465px
	.g-nav-sub-sotsugyoseihe
		a
			background-image url(img/common/header_nav_sub_sotsugyoseihe.png)
			&:hover
				background-image url(img/common/header_nav_sub_sotsugyoseihe_o.png)
	.g-nav-sub-sotugyohogosya
		a
			background-image url(img/common/header_nav_sub_sotsugyohogosya.png)
			&:hover
				background-image url(img/common/header_nav_sub_sotsugyohogosya_o.png)

/* header sub nav for pc
------------------------------------------------- */
.g-nav-mobile
	display none
	position fixed
	z-index 100
	width 100%
	height 100%
	top -3000px
	left 0
	text-align left
	background #FFF url(img/common/header_bg.png) 0 -82px repeat-x
	box-sizing border-box
	.g-logo
		position absolute
		top 12px
		left 12px
		img
			width 231px
	h2
		position absolute
		overflow hidden
		width 40px
		height 40px
		top 10px
		right 10px
		text-indent 100%
		white-space nowrap
		background url(img/common/header_menu_btn.png) left top no-repeat
		background-size 40px 40px
	.g-nav
		width 50%
		height 100%
		margin 62px 0 0 0
		background #FFF url(img/common/header_nav_m_bg.png) right top repeat-y
		overflow-y scroll
		-webkit-overflow-scrolling touch
		li
			line-height 1.3
		a, p
			display block
			padding 12px 5px 12px 12px
			font-weight bold
			text-decoration none
			border-right 1px solid #AAA
			border-bottom 1px solid #AAA
			&.is-active
				color #3F3692
				background #E9F6FF
	.g-nav-main
		a, p
			&.is-active
				border-right-color #E9F6FF
	.g-nav-sub
		position absolute
		z-index 101
		width 50%
		height 100%
		top 62px
		right 0
		background #E9F6FF
		box-sizing border-box
		overflow-y scroll
		-webkit-overflow-scrolling touch
		li
			position relative
			fz-rem(11)
			border none
			&:after
				position absolute
				content ""
				width 10px
				height 10px
				top 50%
				right 10px
				margin -5px 0 0 0
				border-top solid 2px #8890CF
				border-right solid 2px #8890CF
				-webkit-transform rotate(45deg)
				transform rotate(45deg)
		a
			display block
			padding 14px 20px 14px 12px
			font-weight bold
			text-decoration none
			border none
	.g-nav-sub-kyoiku
		display block
	.g-nav-sub-nyuen
		display none
	.g-nav-sub-saiyo
		display none
	.g-nav-sub-sotsugyosei
		display none

/* -------------------------------------------------
contents
------------------------------------------------- */
.g-contents
	margin 0 0 100px
	.g-wrapper
		width $base_width
		margin 0 auto
		text-align left

/* contents
------------------------------------------------- */
.c-header
	margin 0 0 50px
	padding 0 0 25px
	border-bottom 1px solid #8692CE
.c-header-kyoiku
	background url(img/kyoiku/header_illust.jpg) 695px bottom no-repeat
.c-header-nyuen
	background url(img/nyuen/header_illust.png) 783px bottom no-repeat
.c-header-saiyo
	background url(img/saiyo/header_illust.png) 856px bottom no-repeat
.c-header-sotsugyosei
	background url(img/sotsugyosei/header_illust.png) 875px bottom no-repeat

.c-title
	fz-rem(30)
	font-weight normal
	line-height 1.3
	color #3F4C99
	br.mobile
		display none
	span.mobile
		font-s

.c-body
.c-side
	width auto

.c-body
	h3
		margin 75px 0 25px
		fz-rem(21)
		line-height 1.3
		color #708DD8
	h4
		margin 45px 0 15px
		fz-rem(16)
		line-height 1.3
	p
		margin 1.8em 0
		fz-rem(16)
		line-height 2
	a
		color #7DACDD
		text-decoration underline
	img
		width auto
		height auto
		max-width 100%
	ul
	ol
		margin 2em 0 2em 1.6em
		fz-rem(16)
		line-height 2
	table
		width 100%
		margin 20px 0
		fz-rem(14)
		line-height 2
		caption
			padding 10px
			fz-rem(16)
			line-height 1.3
			font-weight bold
			text-align left
			background #DDD
		th
		td
			padding 10px
			border 2px solid #FFF
			vertical-align top
			background #F0F0F0
			&:first-child
				border-left none
			&:last-child
				border-right none
			p
				margin 0 0 1.8em
				fz-rem(14)
				&:last-child
					margin-bottom 0
		th
			min-width 150px
			font-weight bold
			text-align left
			background #DDD
		thead
			th
				border-top none
				border-right none
		.red
			background #FBDFDE
		.blue
			background #DCEFFA
		.green
			background #E0F6C9
		.purple
			background #ECDEF3
		.yellow
			background #FDF9C1
		.orange
			background #FFDFBF
		.gray
			background #DDD
	.link-icon
		padding 0 0 0 25px
		font-weight bold
		background url(img/common/icon_arw_circle_left.png) left center no-repeat
		a
			color #333
			text-decoration none
			&:hover
				text-decoration underline
	a.link-icon-ib
		display inline-block
		padding 0 0 0 25px
		font-weight bold
		color #333
		text-decoration none
		background url(img/common/icon_arw_circle_left.png) left center no-repeat
		&:hover
			text-decoration underline
	.c-heading-1
		margin 0 0 30px
		fz-rem(24)
		line-height 1
		color #3E4C9D
		span
			fz-rem(11)
			font-weight normal
	.c-heading-2
		margin 75px 0 25px
		fz-rem(21)
		line-height 1.3
		color #708DD8
	.note-1
		fz-rem(12)
		font-weight bold
		color #6D8CD9

.c-lead
	overflow hidden
	margin 0 0 80px
	.illust
		float left
	.text
		margin 0 0 0 350px
		p
			margin 0
			&.space
				margin-top 1em
				margin-bottom 1em
				&:last-child
					margin-bottom 0

.c-button-reserve
	margin 25px 0
	text-align center
	//&:last-child
	//	margin-bottom 0
	a, .button-reserve-button-disabled
		display inline-block
		margin 0
		padding 15px 55px
		fz-rem(18)
		line-height 1.2
		text-decoration none
		color #FFF
		background #CABF82
		border-radius 5px
	a
		transition opacity 0.3s ease
		&:hover
			opacity 0.7

	.button-reserve-button-disabled
		text-align center
		background #CCC
	.button-reserve-message
		margin 10px 0 0
		fz-rem(14)
		text-align center
		color #F00

/* icon */
.icon-pdf
	display inline-block
	_display inline
	overflow hidden
	width 17px
	height 18px
	text-indent 100%
	white-space nowrap
	vertical-align middle
	background url(img/common/icon_pdf.png) center center no-repeat

.icon-common
.icon-daiichi
.icon-daini
	width 50px
	fz-rem(11)
	text-align center
	line-height 1.5
	vertical-align middle
	color #FFF
	border-radius 2px
.icon-common
	background-color #B6A569
.icon-daiichi
	background-color #339
.icon-daini
	background-color #396

/* information
------------------------------------------------- */
.information-list
	a
		clear both
		display block
		text-decoration none
		color #333
		border-bottom 1px dotted #CCC
	dl
		padding 20px 10px
		fz-rem(14)
		&:hover
			.text
				text-decoration underline
	dt
		float left
		width 168px
		line-height 1.4
	.icon
		float left
	.text
		margin 0 0 0 238px
		font-weight bold
		line-height 1.4
		color #708DD8

/* -------------------------------------------------
footer
------------------------------------------------- */
.g-footer
	clear both
	text-align left
	.g-wrapper
		position relative
		width $base_width
		margin 0 auto

.g-footer-nav
	clear both
	position relative
	padding 25px 0 45px
	background #F0F0F0
	nav
		float left
		//width 130px
		margin 0 15px 0 0
		padding 0 0 0 15px
		border-left 1px solid #C5C5C5
		&:last-child
			margin-right 0
			border-right 1px solid #C5C5C5
		&.last
			margin-right 0
			border-right 1px solid #C5C5C5
	h2
		margin 0 0 1em 0
		fz-rem(12)
		line-height 1.3
	ul
		list-style-type disc
		margin 0 0 1em 1.3em
	li
		fz-rem(11)
		line-height 1.6
	.g-nav-kyoiku
		width 150px
	.g-nav-nyuen
		width 148px
	.g-nav-saiyo
		width 115px
	.g-nav-sotugyo
		width 118px
	.g-nav-en
		width 137px
		//width 140px
	.g-nav-information
		width 125px

.g-footer-contact
	position relative
	z-index 10
	clear both
	background #CABD88
	.g-wrapper
		position relative
		padding 20px 0 30px
	h2
		margin 0 0 35px
		fz-rem(18)
		font-weight normal
		line-height 1.3
		color #FFF
	.g-button
		width 210px
		a
			display block
			padding 0 0 0 10px
			fz-rem(16)
			line-height 36px
			color #BAAA67
			background #FFF
			border-radius 3px
			rollover-alpha()
			span
				display block
				padding 0 0 0 36px
				background url(img/common/footer_contact_icon.png) left center no-repeat
	.g-footer-illust
		position absolute
		top -46px
		right 11px
		div
			margin 0 0 22px
.g-footer-info
	clear both
	.g-wrapper
		padding 10px 0
		text-align left
	ul
		list-style-type none
		overflow hidden
	li
		float left
		margin 0 50px 0 0
		fz-rem(14)
		line-height 1.3
		&.g-arrow
			float right
			margin 0
			a
				display block
				padding 0 0 0 12px
				fz-rem(12)
				line-height 1.3
				background url(img/common/information_list_icon_arw.png) left center no-repeat

.g-pagetop
	position fixed
	z-index 100
	right 50px
	bottom 50px
	p
		position relative
	a
		display block
		position relative
		overflow hidden
		width 60px
		height 60px
		text-align center
		text-indent 100%
		white-space nowrap
		background #CABE85 url(img/common/pagetop_arw.png) center center no-repeat
		border-radius 5px
		rollover-alpha()

* html .g-footer .g-pagetop a
	display none

/* -------------------------------------------------
media query for global
------------------------------------------------- */
@media screen and (max-width: $viewport_width)
	.g-header
		min-height 62px
		margin-bottom 35px
		//background-size 62px auto
		background-position 0 -82px
		.g-wrapper
			width auto
		.g-logo
			top 12px
			left 12px
			img
				width 231px
		nav
			h2
				display block
				position absolute
				overflow hidden
				width 40px
				height 40px
				top 10px
				right 10px
				text-indent 100%
				white-space nowrap
				cursor pointer
				background url(img/common/header_menu_btn.png) left top no-repeat
				background-size 40px 40px
		.g-nav
			display none
		.g-address
			display none
	
	.g-nav-mobile
		display block
	
	.g-contents
		margin-bottom 50px
		.g-wrapper
			width auto
			margin auto
	
	.c-header
		margin-bottom 30px
		padding 0 12px 14px
		background-position 97% bottom
		background-size auto 90%
	
	.c-title
		fz-rem(20)
	
	.c-body
		margin-left 12px
		margin-right 12px
		fz-rem(16)
		line-height 1.8
		p
			margin 0 0 15px
			fz-rem(14)
			line-height 1.8

	.c-lead
		margin-bottom 30px
		.illust
			img
				width 120px
		.text
			margin-left 140px
			p
				fz-rem(14)
				line-height 1.8em
	.c-body
		h3
			margin-top 30px
			margin-bottom 15px
			fz-rem(16)
		h4
			margin-top 30px
			margin-bottom 15px
			fz-rem(14)
		p
			margin 15px 0
			fz-rem(14)
			line-height 1.8
		ul
		ol
			margin 15px 0 15px 1.6em
			fz-rem(14)
			line-height 1.8
		table
			margin 15px 0
			fz-rem(12)
			line-height 1.8
			caption
				fz-rem(14)
			th
				//width 100px
				min-width 100px
		.link-icon
			fz-rem(14)
		.c-heading-1
			margin-top 30px
			margin-bottom 15px
			fz-rem(18)
		.c-heading-2
			margin-top 30px
			margin-bottom 15px
			fz-rem(16)

	.c-button-reserve
		margin 30px 0
		a, .button-reserve-button-disabled
			display inline-block
			margin 0
			padding 15px 40px
			fz-rem(16)
		.button-reserve-message
			fz-rem(12)
	
	.information-list
		dl
			padding 10px 0
		dt
			float none
			width auto
			display inline-block
			_display inline
			width auto
			margin-right 1em
			margin-bottom 5px
			fz-rem(12)
			vertical-align middle
		.icon
			float none
			display inline-block
			_display inline
			fz-rem(10)
			vertical-align middle
		.text
			width auto
			margin-left 0
	
	.c-side
		width auto
	
	.g-footer
		.g-wrapper
			width auto
			margin 0 12px
	.g-footer-nav
		display none
	.g-footer-contact
		.g-wrapper
			padding 10px 0
		h2
			margin-bottom 10px
			fz-rem(10)
		.g-button
			width auto
	.g-footer-illust
		display none
	.g-footer-info
		.g-wrapper
			//padding-bottom 60px
			padding-bottom 10px
		li
			margin-right 13px
			fz-rem(12)
			&.g-arrow
				clear both
				float none
				padding 10px 0 0
	.g-pagetop
		right 10px
		bottom 10px
		a
			width 40px
			height 40px
	

@media screen and (max-width: $viewport_width_2)
	.c-title
		br.mobile
			display inline
		span.mobile
			fz-rem(14)
	.c-lead
		.illust
			img
				width 100px
		.text
			margin-left 0
			margin-right 0
			p
				margin-bottom 1em
				&:last-child
					margin-bottom 0
				&.space
					margin-top 0
					margin-bottom 1em
					&:last-child
						margin-bottom 0
	.c-body
		table
			th
				//width auto
				min-width auto
				min-width initial
		.link-icon
			fz-rem(12)
			line-height 20px

