// ===================================================================
// mixinやらCSS3やら、extendも。
// ===================================================================

vendor(prop, args)
	-webkit-{prop} args
	-moz-{prop} args
	{prop} args

// border-radius
border-radius()
	vendor('border-radius', arguments)

// min-height
min_height($value)
	min-height: $value
	//_height: $value;
	height: auto !important
	height: $value

// clearfix
cf()
	zoom: 1
	&:before, &:after
		content: ""
		display: table
	&:after
		clear: both

// font-size rem
fz-rem(size)
	font-size unit(size, px)
	font-size unit((size / 10), rem)

// rollover
rollover-alpha()
	transition-property opacity
	transition-duration 0.3s
	&:hover
		text-decoration none
		filter unquote('alpha(opacity=60)')
		-moz-opacity 0.6
		opacity 0.6
